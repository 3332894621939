

.headerStyle { 
    background: linear-gradient( 
        135deg, 
        #FFFFFF, 
        #285318
    ); 
    color: #285318; 
    
} 