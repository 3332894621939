
.divBorder {
    width: fit-content;
  }
  .buttonBG {
    background-color: #285318; /* Green */
    border-color: #285318;
  }
  .buttonBG:hover {
    background-color:#ffffff;
    transition: 0.7s;
    color: #285318;
    border-color: #285318;
}
.headerStyle { 
    background: linear-gradient( 
        135deg, 
        #FFFFFF, 
        #285318
    ); 
    color: #285318; 
    
} 